import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import StickyElementWatcher from 'react-sticky-element-watcher'
import {Link} from 'gatsby'

import {Avatar} from '../components/Svgs'
import {ArrowLeft} from '../components/Svgs'

interface SHProps {
    isStuck?: boolean;
}

const StyledHeader = styled.header<SHProps>`
    position: sticky;
    top: 0;
    z-index: 100;
    height: 0;
    
    .wrap {
        background-color: ${p => p.isStuck ? '#000A': '#0000'};
        transition: background-color 5s ease-in-out;
        display: flex;
        padding: 2.125rem 0;
        margin: 0;
        position: relative;
    }

    .avatar {
        margin: 0 3.125rem;
        display: inline;
    }
`

const Search = styled.div`
    display: flex;
    align-items: center;
    height: 2.5rem;
    width: 18.75rem;
    border: 1px solid hsla(0, 100%, 100%, 80%);
    color: hsla(0, 100%, 100%, 80%);
    border-radius: 0.25rem;
    position: relative;

    &.extra-dark {
        background-color: #000A;
        transition: background-color 2s ease-in-out;
    }
    input {
        background-color: #0000;
        border: none;
        color: inherit;
        margin: 0 1rem;
        font-size: 25px;
        width: 14rem;
        font-family: ${p => p.theme.roboto};
        outline: none;
    }

    .search-slash {
        position: absolute;
        right: 1rem;
        line-height: 30px;
        width: 30px;
        border: inherit;
        border-radius: inherit;
        text-align: center;
        font-size: 25px;
    }
`

const Header = (props) => {
    const inputEl = useRef(null);
    const [searchValue, setSearchValue] = useState('')

    function inputHandler({target}) {
        if(target.value === '/') {
            return
        }
        setSearchValue(target.value)
    }

    function slashHandler({key}) {
        if(key === '/') {
            inputEl.current.focus();
        }
    }
    useEffect(() => {
        document.addEventListener("keydown", slashHandler);
        return () => document.removeEventListener('keydown', slashHandler)
    },[])


    return (
        <StickyElementWatcher render={({isStuck}) => (
            <StyledHeader isStuck={isStuck}>
                <div className='wrap'>
                    <Avatar className='avatar'/>
                    {props.showBack &&<Link to='/'><ArrowLeft className="shadow-2xl" colorClass="text-white" /></Link>}
                    {/* <Search className={isStuck ? 'extra-dark' : ''}>
                        <input
                            value={searchValue}
                            onChange={inputHandler}
                            ref={inputEl}
                            type='text'
                            placeholder='    Search'
                        />
                        <span className='search-slash'>/</span>
                    </Search> */}
                </div>
            </StyledHeader>
        )} />
    )
}

export default Header
