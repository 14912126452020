import React from "react";
import AvatarSvg from "../assets/avatarLogo.svg";

export const Avatar = props => <AvatarSvg {...props} />

export const ArrowLeft = props => (
  <svg className='w-10 h-10 shadow-2xl' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <path className={`fill-current shadow-2xl ${props.colorClass}`} d='M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12z' />
    {/* <path className='fill-current text-red' d='M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12z' /> */}
  </svg>
)
