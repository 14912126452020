import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import railsCasts from 'react-syntax-highlighter/dist/esm/styles/hljs/railscasts'
import Figure from './Figure'

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    code: ({node}) => <SyntaxHighlighter className='ml-8 my-8' language={node.language} style={railsCasts}>{node.code}</SyntaxHighlighter>,
    embedBlock: ({node}) => (<iframe
      className='mx-auto'
      title={node.title}
      width='500'
      height={node.height}
      src={node.url}
    />)
  }
}

export default serializers
