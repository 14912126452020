import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage, getFixedGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'

export default ({node}) => {
  const fluidProps = getFluidGatsbyImage(node.asset._id, {maxWidth: 1000}, clientConfig.sanity)
  return (
    <figure className='py-2'>
      <Img fluid={fluidProps} alt={node.alt} />
      <figcaption>{node.caption}</figcaption>
    </figure>
  )
}
