import React from 'react'
import {graphql} from 'gatsby'
import Image from 'gatsby-image'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'
import PortableText from '../components/portableText.js'
import Header from '../components/Header.tsx'
import { NewsletterPitch } from '../components/NewsletterPitch'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: {eq: $id}) {
      id
      preview
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        asset {
          fluid(maxWidth: 2440, maxHeight: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      socialMediaPreview {
        asset {
          fluid(maxWidth: 2440, maxHeight: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
      authors {
        _key
      }
    }
  }
`

const BlogPostTemplate = props => {
  const {data, errors} = props
  const post = data && data.post
  return (<>
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={((post.socialMediaPreview && post.socialMediaPreview.asset && post.socialMediaPreview.asset.fluid && post.socialMediaPreview.asset.fluid.src)) || (post.mainImage && post.mainImage.asset && post.mainImage.asset.fluid && post.mainImage.asset.fluid.src)}
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}

      <div>
        <Header showBack={1} />
        <Image fluid={post.mainImage.asset.fluid} />
        <h1 className='max-w-6xl mx-auto px-2 text-4xl pt-10' >{post.title}</h1>
        <div style={{height: '40vh'}} />
        {post && post.preview && <div className='max-w-5xl mx-auto italic text-base'>
          You're looking at a draft, there will be mistakes and roughness in general.
        </div>}
        {post && <div className='max-w-5xl p-2 mx-auto text-xl mb-40' style={{fontFamily: "'Lora', serif"}}>
          <PortableText blocks={post._rawBody} />
        </div>}
      </div>
      <NewsletterPitch />
    </Layout></>
  )
}

export default BlogPostTemplate
